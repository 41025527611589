.dashobardsContainer {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.TitanContainer {
  color: var(--primaryTextColor);
}

.TitanContainer a {
  color: var(--primaryTextColor);
}

.TitanContainer a:hover {
  color: var(--primaryTextColor);
}

.TitanContainer .createDashboardButton {
  margin: 10px;
}
