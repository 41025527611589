.paramDialog .parametersContent {
  margin: 20px 20px 0;
}

.paramDialog .parametersContent .bp3-switch {
  margin-bottom: 20px;
}

.paramDialog .parametersContent .bp3-form-group {
  margin-bottom: 5px;
  margin-bottom: 25px;
}

.paramDialog .parametersContent .bp3-form-group.invalid {
  margin-bottom: 5px;
}

.paramDialog .parametersContent .parametersContent_left {
  width: 350px;
  float: left;
}

.paramDialog .parametersContent .parametersContent_right {
  width: 380px;
  float: right;
}

.paramDialog .parametersContent input {
  width: 100%;
}

.paramDialog .parametersContent .buttons {
  float: right;
}

.paramDialog .parametersContent .buttons button {
  margin-left: 10px;
}