.DashboardHeader {
  margin-bottom: 10px;
}

.DashboardHeader .DashboardTitle,
.DashboardHeader .DashboardDescription {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.DashboardTitle h2 {
  margin: 0;
}

.DashboardTitle button.editButton {
  margin: -0px 0 0 5px;
}

.DashboardHeader .hidden {
  display: none;
}
