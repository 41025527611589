.ChartParametersHeader {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  margin-bottom: 10px;
}

.chartNamePopover {
  width: 300px;
  margin: 0;
}

.chartNamePopover .buttons {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
}

.chartNamePopover .buttons button {
  margin-left: 10px;
}