
.Attribute {
  background: var(--elevatedBackground);
  padding: 5px 5px 5px 10px;
  margin: 10px 0;
  border-radius: 5px;
  transition: background 0.2s, color 0.2s;
}

.Attribute .attributeSelection {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Attribute .attributeSelection span.bp3-icon {
  color: var(--mutedTextColor);
  margin: 0 10px 0 3px;
}

.Attribute .attributeSelection button {
  margin-left: 5px;
}

.Attribute .attributeParameters {
  margin: 5px 0 0 33px;
}