.editableContent {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  z-index: 3;
}

.editableContent input {
  width: 600px;
}

.editableContent button.editButton {
  margin-left: 5px;
}

.editableContent .hidden {
  display: none;
}

.editableContent .placeholder {
  color: #ddd;
}
