
.Filter {
  background: var(--elevatedBackground);
  padding: 5px 5px 5px 10px;
  margin: 10px 0;
  border-radius: 5px;
  transition: background 0.2s, color 0.2s;
}

.Filter .filterSelection {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Filter .filterSelection span.bp3-icon{
  color: var(--mutedTextColor);
  margin: 0 10px 0 3px;
}

.Filter .filterSelection button {
  margin-left: 5px;
}

.Filter .filterParameters {
  margin: 5px 0 0 25px;
}

.Filter .filterValues {
  margin: -1px 35px 0 33px;
}