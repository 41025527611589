.ExplorerFilters {
  clear: both;
  display: flex;
  justify-content: space-between;
}

.ExplorerFilters > div.dateFilters, .ExplorerFilters > section {
  flex: 1;
}

.ExplorerFilters > section {
  margin-left: 20px
}

.ExplorerFilters .dateFilters section {
  margin: 0 0 5px;
  display: flex;
  justify-content: space-between;
}

.ExplorerFilters .dateFilters section.primaryDateFilter button {
  margin-right: 86px;
}

.ExplorerFilters .dateFilters section.additionalDateFilter .bp3-html-select {
  width: 143px;
}

.ExplorerFilters h4 {
  margin-bottom: 5px;
}

.ExplorerFilters .dateFilters {
  display: flex;
  flex-direction: column;
}