@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i&display=swap);
body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

body.bp3-light {
  --appBackground: #f5f8fa;
  --elevatedBackground: #EBF1F5;
  --pronouncedBackground: #ced9e0;
  --cardBackground: #f5f8fa;
  --sideBarBackground: #202B33;
  --sectionBackground: #FFFFFF;
  --primaryTextColor: #3b4e5d;
  --mutedTextColor: #4A5D6C;
  --disabledTextColor: #5C7080;
  --dividerColor: #c0ccd6;
  --shadow: #30404d;
  --iconFill: #5c7080;

  --primaryBackground: #f5f7f7;
  --secondaryBackground_lightest: #ebf1f5;
  --secondaryBackground_lighter: #e1e8ed;
  --secondaryBackground: #ced9e0;
  --secondaryBackground_darker: #bfccd6;
  --linkTextColor: #0f6ba3;
  --darkHeadingBackground: #394b59;
  --darkHeadingBackgroundPlus: #30404d;
  --darkHeadingTextColor: #f5f7f7;
  --darkBorder: #27343F;
  transition: background 0.2s, color 0.2s;
}

body.bp3-dark {
  --appBackground: #30404d;
  --elevatedBackground: #394B59;
  --pronouncedBackground: #4A5D6C;
  --cardBackground: #3e4c5c;
  --sectionBackground: #293742;
  --sideBarBackground: #202B33;
  --primaryTextColor: #F5F8FA;
  --mutedTextColor: #A7B6C2;
  --disabledTextColor: #778793;
  --dividerColor: #778793;
  --linkTextColor: #0f6ba3;
  --shadow: #30404d;
  --iconFill: #a7b6c2;
  transition: background 0.2s, color 0.2s;
}

/*******************************
*****   GENERAL SETTINGS   *****
*******************************/
body {
  -ms-scroll-chaining: none;
      overscroll-behavior: none;
  font-family: 'Open Sans', 'Helvetica Neue', 'sans-serif';
  background: var(--appBackground);
}

body h2 {
  margin-bottom: 10px;
}

.App button {
  cursor: pointer;
}

.App a:hover {
  text-decoration: none;
}
.loginPage {
  height: 100vh;
  color: #e6e9ed;
  background: #30404d;
}

.loginPageBody {
  display: flex;
  justify-content: center;
  padding-top: 200px;
  font-size: 1.2rem;
}

footer.buildNumber {
  position: absolute;
  bottom: 0;
  font-size: 0.7rem;
  font-style: italic;
  padding: 10px;
}
.loadingPage {
  height: 100vh;
  color: #e6e9ed;
  background: #30404d;
}

.loadingPageBody {
  display: flex;
  justify-content: center;
  padding-top: 200px;
  font-size: 1.2rem;
}

.loadingPageBody button {
  margin: -10px 0 0 30px;
}

footer.buildNumber {
  position: absolute;
  bottom: 0;
  font-size: 0.7rem;
  font-style: italic;
  padding: 10px;
}

.Navbar .Heading a {
  color: #f3f8fa;
}

.Navbar .menuLink a {
  color: #bbcdd7;
}

.Navbar .menuLink a:hover {
  color: #f3f8fa;
}

.Navbar ul {
  list-style: none;
  padding-left: 20px;
}

.Navbar li {
  float: left;
  margin-right: 10px;
}

.Navbar li.menuLink a {
  padding: 10px;
}

.Navbar .darkModeButton {
  margin-right: 10px;
  color: var(--primaryBackground)
}
.SideBar {
  background: var(--sideBarBackground);
  color: #e6e9ed;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 67px;
  height: 100%;
  /* overflow: auto; */
  overflow-x: hidden;
  overflow-y: auto;
  white-space: nowrap;
  padding-top: 50px;
  transition: width 0.15s;
  z-index: 10;
}

.SideBar.open {
  width: 250px;
}

button.openCloseButton {
  margin: 18px;
  outline: none;
}

button.openCloseButton:hover {
  background: var(--sideBarBackground) !important;
}

button.openCloseButton svg {
  color: #e6e9ed;
}

.MenuGroup {
  position: static;
}

.MenuGroup:first-child {
  margin-top: 20px;
}

.MenuGroupHeader {
  display: flex;
  align-items: center;
  font-size: 1rem;
  color: #e6e9ed;
  margin-bottom: 0;
}

.MenuGroupHeader > span.MenuGroupHeaderIcon {
  padding: 15px 21px;
}

.MenuGroupHeader > span.MenuGroupTitle {
  margin-top: 10px;
}

.MenuGroup.isClosed .MenuGroupHeader > span.hovered {
  background: #334b5a;
}

.MenuGroupHeader:hover {
  background: #334b5a;
  color: #e6e9ed;
  cursor: pointer;
}

.MenuGroupHeader.active {
  background: #202b33;
}

.MenuGroupTitle {
  position: relative;
  top: -4px;
}

.MenuGroupItem {
  display: block;
  font-size: 0.8rem;
  color: #e6e9ed !important;
  margin: 5px 0 5px 20px;
  padding: 5px 7px;
}

.MenuGroupItem.active {
  font-weight: 700;
  padding-left: 7px;
  border-left: solid 3px #e6e9ed;
}

.MenuGroupItem:hover {
  background: #334b5a;
  color: #e6e9ed;
  cursor: pointer;
}

.MenuGroup.isClosed:hover .MenuGroupHover {
  display: block;
  background: #334b5a;
}

.MenuGroupHover {
  background: #334b5a;
  position: fixed;
  top: 0;
  left: 67px;
  width: 200px;
  padding: 0;
}

.MenuGroupHoverTitle {
  font-size: 1rem;
  font-weight: 600;
  padding: 20px;
  padding-bottom: 2px;
}

.MenuGroupHoverItem {
  display: block;
  color: #e6e9ed !important;
  margin: 0;
  padding: 10px 20px;
  font-size: 0.8rem;
  cursor: pointer;
}

.MenuGroupHoverItem:last-child {
  margin-bottom: 10px;
}

.MenuGroupHoverItem:hover {
  background: #5c7080;
  color: #e6e9ed;
}

.MenuGroupHoverItem.active {
  font-weight: 700;
  padding-left: 17px;
  border-left: solid 3px #e6e9ed;
}


footer.buildNumber {
  position: absolute;
  bottom: 0;
  font-size: 0.7rem;
  font-style: italic;
  padding: 10px;
}
#breadcrumbs {
  font-size: 1rem;
  display: inline-block;
  margin: 10px;
  padding: 6px 10px;
  background: var(--pronouncedBackground);
  border: 1px solid #c0ccd6;
  border-radius: 3px;
  transition: background 0.2s, color 0.2s;
}

#breadcrumbs a {
  color: var(--linkTextColor) !important;
}

#breadcrumbs .chevron-right {
  color: var(--linkTextColor);
  position: relative;
  top: -2px;
  padding: 0 5px;
}


.bp3-dark #breadcrumbs {
  border: 1px solid #27343F;
}

.bp3-dark #breadcrumbs a {
  color: var(--primaryTextColor) !important;
}

.bp3-dark #breadcrumbs .chevron-right {
  color: var(--mutedTextColor);
}
.CategoryParamsContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.NumberOfItemsDefinition {
  height: 75px;
}

.ParamDefinition {
  width: 49%;
  background: var(--elevatedBackground);
  margin: 5px 5px;
  padding: 12px 8px 8px;
  border-radius: 4px;
}

.ParamDefinition header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.ParamDefinition h4 {
  margin: 0 10px 0 0;
}

.ParamDefinition .ParamDefinitionInput {
  width: 50px;
}

.ParamDefinition .ParamDefinitionInput input {
  text-align: right;
}

.ParamDefinition .rangeSlider {
  width: 95%;
  margin: 10px auto;
}

.ParamDefinition .description {
  display: block;
  width: 90%;
}

.ParamDefinition .helperText {
  display: block;
  width: 90%;
  margin: 10px 0;
}


.CategoryDefinition .CategoryParamsContainer {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}

.CategoryDefinition h3 {
	margin: 0;
}

.CategoryDefinition button {
	display: block;
}
.categoryParamsTable {
	width: 100%;
	border: none;
}

.categoryParamsTable tbody td {
	border: none;
}

.categoryParamsTable thead tr th {
	text-align: center;
	vertical-align: middle;
	padding-bottom: 10px;
	border-bottom: solid 1px var(--dividerColor);	
}

.categoryParamsTable th.name {
	width: 50%;
}

.categoryParamsTable tbody .parameterValueInput {
  width: 90px;
  margin: 0 auto;
}

.categoryParamsTable tbody .parameterValueInput input {
	text-align: center;
}

.categoryParamsTable tbody td {
	text-align: center;
}

.categoryParamsTable tbody tr {
  height: 40px
}

.addItemSection {
  margin-top: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.addItemSection .addItemSectionInput {
	width: 200px;
	margin: 0 10px;
}
.CategoryDefinitions {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}

.BulkCategoryDefinition {
	width: 100%;
}

.ListCategoryDefinition {
	width: 46.5%;
}

.BulkCategoryDefinition h3, .ListCategoryDefinition h3 {
	margin-top: 5px;
}
.uploadButton,
.removeButton,
.deleteButton {
  padding: 7px 7px;
  margin-left: 10px;
  color: white;
  border: none;
  border-radius: 4px;
  background: #5c7080;
}

.uploadButton:disabled,
.removeButton:disabled,
.deleteButton:disabled {
  background: #a7b6c2;
  cursor: not-allowed;
}

.uploadButton:disabled:hover,
.removeButton:disabled:hover,
.deleteButton:disabled:hover {
  background: #a7b6c2;
}

.removeButton:hover,
.deleteButton:hover {
  background: red;
}

.uploadButton:hover {
  background: #69c576;
}

.bigButton {
  padding-top: 20px;
  padding-bottom: 20px;
  width: 75px;
  color: white;
  border: none;
  border-radius: 10px;
  opacity: 0.8;
}

.bigButton:hover {
  opacity: 1;
}

.fileSelectorSection {
  background: var(--sectionBackground);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  float: left;
  width: 49%;
  height: 250px;
  border: 1px dashed var(--pronouncedBackground);
  border-radius: 5px;
  transition: background 0.2s, color 0.2s;
}

.fileSelectorSection.active {
  border: 2px dashed #69c576;
}
.fileSelectorSection.active button {
  background: #69c576;
}

.fileSelectorSection button:hover {
  background: #69c576;
}


.circularProgressBar {
  display: block;
  margin: 0 10px auto 0;
}

.fileToUploadRowSection {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background: var(--elevatedBackground);
  margin: 10px 5px;
  padding: 10px;
  border-radius: 5px;
}

.fileToUploadRowSection p {
  margin-bottom: 0;
}

.fileToUploadRowSection .fileName {
  margin-left: 10px;
  flex-grow: 5;
}

.fileToUploadRowSection .fileSize {
  margin-right: 10px;
}

.fileToUploadRowSection button {
  margin-left: 10px;
}
.filesToUploadSection {
  background: var(--sectionBackground);
	float: right;
	margin-right: 3px;
	height: 250px;
	width: 50%;
  border: solid 1px var(--pronouncedBackground);
  border-radius: 5px;
	overflow: scroll;
  transition: background 0.2s, color 0.2s;
}
.simpleTable {
  width: 100%;
  table-layout: fixed;
  overflow-wrap: break-word;
}

.simpleTable p {
  margin-bottom: 0;
}
.simpleTable label {
  margin-bottom: 0;
}

.simpleTable thead {
  margin: 5px;
}

.simpleTable thead th {
  padding-bottom: 5px;
  margin-bottom: 5px;
  cursor: pointer;
}

.simpleTable td {
  text-align: center;
  padding: 2px 0;
}

.fileListTable {
  background: var(--sectionBackground);
  border: solid 1px var(--pronouncedBackground);
  border-radius: 5px;
  transition: background 0.2s, color 0.2s;
}

.fileListTable thead tr th {
  padding: 10px 0;
  border-bottom: solid 1px var(--dividerColor);
}

.fileListTable tbody tr:hover {
  background: var(--elevatedBackground);
}

.fileListTable tbody {
  padding: 0 10px;
}

.fileListTable tbody tr td {
  padding: 5px 0;
}

.fileListTable tr:not(:first-child) td {
  border-top: solid 1px var(--elevatedBackground);
}

.datasetsHeader {
display: flex;
justify-content: space-between;
}

.datasetListTable {
  background: var(--sectionBackground);
  border: solid 1px var(--pronouncedBackground);
  border-radius: 5px;
  transition: background 0.2s, color 0.2s;
}

.datasetListTable thead tr th {
  padding: 10px 0;
  border-bottom: solid 1px var(--dividerColor);
}

.datasetListTable tbody tr:hover {
  background: var(--elevatedBackground);
}

.datasetListTable tbody {
  padding: 0 10px;
}

.datasetListTable tbody tr td {
  padding: 5px 0;
}

.datasetListTable tr:not(:first-child) td {
  border-top: solid 1px var(--elevatedBackground);
}

.datasetsButtons {
  margin-top: 10px;
}

.datasetsButtons button {
  margin-right: 10px;
}
.inputFilesTable {
  font-size: 0.8rem;
  background: var(--sectionBackground);
  border: solid 1px var(--pronouncedBackground);
  border-radius: 5px;
  transition: background 0.2s, color 0.2s;
}

.inputFilesTable thead tr th {
  padding: 10px 0;
  border-bottom: solid 1px var(--dividerColor);
}

.inputFilesTable tbody tr:hover {
  background: var(--elevatedBackground);
}

.inputFilesTable tbody {
  padding: 0 10px;
}

.inputFilesTable tbody tr td {
  padding: 5px 0;
}

.inputFilesTable tr:not(:first-child) td {
  border-top: solid 1px var(--elevatedBackground);
}

.FieldsConfiguration .importExportButtons {
  margin: 20px 10px 10px 0;
  display: flex;
  justify-content: flex-end;
}

.FieldsConfiguration .importExportButtons button {
  margin-left: 10px;
}

.FieldsConfiguration .closeButton {
  margin: 10px 10px 0 0;
  display: flex;
  justify-content: flex-end;
}

.fieldsConfigurationTable {
  background: var(--sectionBackground);
  border: solid 1px var(--pronouncedBackground);
  border-radius: 5px;
  margin: 10px;
  width: calc(100% - 20px);
}

.fieldsConfigurationTable thead tr th {
  padding: 10px 0;
  border-bottom: solid 1px var(--dividerColor);
}

.fieldsConfigurationTable tbody tr:hover {
  background: var(--elevatedBackground);
}

.fieldsConfigurationTable tbody {
  padding: 0 10px;
}

.fieldsConfigurationTable tbody tr td {
  padding: 5px 0;
}

.fieldsConfigurationTable tr:not(:first-child) td {
  border-top: solid 1px var(--elevatedBackground);
}

.InputsDefinition .dataSource button {
  margin-right: 20px;
}

.InputsDefinition .filesConfiguration {
  margin-top: 30px;
}

.RunParametersDefinition .ETLschemaButtons button {
  margin-right: 20px;
}

.RunParametersDefinition .ETLparameters {
  margin-top: 30px;
}

.RunParametersDefinition .ETLparametersContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}


.ETLParamDefinition {
  width: 49%;
  background: var(--elevatedBackground);
  /* margin: 5px 5px; */
  padding: 15px 20px 15px 15px;
  border-radius: 4px;
  transition: background 0.2s, color 0.2s;
}

.ETLParamDefinition header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.ETLParamDefinition h4 {
  margin: 0 10px 0 0;
  font-size: 1rem;
}

.ETLParamDefinition .ETLparam {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;
}

.ETLParamDefinition span.ETLparamName {
  width: 200px;
}

.ETLParamDefinition .ParamDefinitionInput {
  width: 50px;
}

.ETLParamDefinition .ParamDefinitionInput input {
  text-align: right;
}

.ETLParamDefinition .rangeSlider {
  width: 95%;
  margin: 10px auto;
}

.ETLParamDefinition .description {
  display: block;
  width: 90%;
}

.ETLParamDefinition .helperText {
  display: block;
  width: 90%;
  margin: 10px 0;
}
.RunDefinition {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 200px;
}

.RunDefinition > * {
  margin: 20px;
}

.RunDefinition .runStatus {
  width: 400px;
  margin-left: 0;
  display: flex;
  justify-content: left;
  align-items: center;
}

.RunDefinition .runStatus > span {
  margin-left: 10px;
}

.RunDefinition .runStatus span.runStatusMessage {
  font-size: 0.8rem;
  font-style: italic;
}
.bp3-tab {
  color: var(--primaryText);
}

[aria-selected="true"], .bp3-tab:not([aria-disabled="true"]):hover {
  color: #106ba3;
}

.DatasetConfiguration header {
  display: flex;
  justify-content: space-between;
}

.DatasetConfiguration header .rightSideButtons > * {
  float: left;
  margin-left: 10px;
}

.DatasetConfiguration header .rightSideButtons .bp3-switch {
  margin-top: 7px;
}
.paramDialog .parametersContent {
  margin: 20px 20px 0;
}

.paramDialog .parametersContent .bp3-switch {
  margin-bottom: 20px;
}

.paramDialog .parametersContent .bp3-form-group {
  margin-bottom: 5px;
  margin-bottom: 25px;
}

.paramDialog .parametersContent .bp3-form-group.invalid {
  margin-bottom: 5px;
}

.paramDialog .parametersContent .parametersContent_left {
  width: 350px;
  float: left;
}

.paramDialog .parametersContent .parametersContent_right {
  width: 380px;
  float: right;
}

.paramDialog .parametersContent input {
  width: 100%;
}

.paramDialog .parametersContent .buttons {
  float: right;
}

.paramDialog .parametersContent .buttons button {
  margin-left: 10px;
}
.ExplorerFilters {
  clear: both;
  display: flex;
  justify-content: space-between;
}

.ExplorerFilters > div.dateFilters, .ExplorerFilters > section {
  flex: 1 1;
}

.ExplorerFilters > section {
  margin-left: 20px
}

.ExplorerFilters .dateFilters section {
  margin: 0 0 5px;
  display: flex;
  justify-content: space-between;
}

.ExplorerFilters .dateFilters section.primaryDateFilter button {
  margin-right: 86px;
}

.ExplorerFilters .dateFilters section.additionalDateFilter .bp3-html-select {
  width: 143px;
}

.ExplorerFilters h4 {
  margin-bottom: 5px;
}

.ExplorerFilters .dateFilters {
  display: flex;
  flex-direction: column;
}

.ExplorerScreenshotsList .screenshotsControls {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ExplorerScreenshotsList .controlButtons {
  margin-top: 15px;
  display: flex;
  justify-content: flex-start;
}

.ExplorerScreenshotsList .controlButtons button {
  margin-right: 15px;
}

.ExplorerScreenshotsList .screenshotsControls .screenshotsCounts {
  color: var(--mutedTextColor);
  font-size: 0.85rem;
  margin: 20px 0 0;
}


.ExplorerScreenshotsListTable {
  background: var(--sectionBackground);
  border: solid 1px var(--pronouncedBackground);
  border-radius: 5px;
  margin-top: 10px;
  transition: background 0.2s, color 0.2s;
}

.ExplorerScreenshotsListTable thead tr th {
  padding: 10px 0;
  border-bottom: solid 1px var(--dividerColor);
}

.ExplorerScreenshotsListTable tbody tr:hover {
  background: var(--elevatedBackground);
}

.ExplorerScreenshotsListTable tbody {
  padding: 0 10px;
}

.ExplorerScreenshotsListTable tbody tr td {
  padding: 0;
  height: 35px;
}

.ExplorerScreenshotsListTable tbody tr td:last-child {
  padding-right: 10px;
}

.ExplorerScreenshotsListTable tbody tr td p {
  padding: 5px 0;
}

.ExplorerScreenshotsListTable tbody tr td:hover {
  cursor: pointer;
}

.ExplorerScreenshotsListTable tr:not(:first-child) td {
  border-top: solid 1px var(--elevatedBackground);
}

.ExplorerScreenshotsListTable {
  width: 100%;
  table-layout: fixed;
  overflow-wrap: break-word;
}

.ExplorerScreenshotsListTable p {
  margin-bottom: 0;
}
.ExplorerScreenshotsListTable label {
  margin-bottom: 0;
}

.ExplorerScreenshotsListTable thead {
  margin: 5px;
}

.ExplorerScreenshotsListTable thead th {
  padding-bottom: 5px;
  margin-bottom: 5px;
  cursor: pointer;
}

.ExplorerScreenshotsListTable td {
  text-align: center;
  padding: 2px 0;
}

/****************************
*****   Loading state   *****
****************************/

.ExplorerScreenshotsListTable tbody.loading tr td {
  height: 200px;
  width: 100%;
}
.dashboardItemContainer {
  margin: 10px;
  max-width: 400px;
  flex-basis: 300px;
  flex-grow: 1;
  position: relative;
  background: var(--cardBackground) !important;
  /* border: 0.5px solid var(--dividerColor); */
  border-radius: 5px;
  padding: 15px;
  transition: background 0.2s, color 0.2s;
}

.dashboardItemContainer:hover {
  box-shadow: 0 0 10px var(--shadow);
}

.dashboardItem {
  height: 90px;
  overflow-y: hidden;
}

.dashboardItemName {
  max-width: 90%;
}

.dashboardItem h3 {
  margin: 0;
  overflow: hidden;
}

.dashboardItem p {
  font-size: 0.9rem;
  font-style: italic;
}

.dashboardItemHeader {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 5px;
}

.dashboardItemButtons {
  display: flex;
}

.dashboardItemButtons button {
  margin-left: 10px;
}

.dashobardsContainer {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.TitanContainer {
  color: var(--primaryTextColor);
}

.TitanContainer a {
  color: var(--primaryTextColor);
}

.TitanContainer a:hover {
  color: var(--primaryTextColor);
}

.TitanContainer .createDashboardButton {
  margin: 10px;
}

.editableContent {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  z-index: 3;
}

.editableContent input {
  width: 600px;
}

.editableContent button.editButton {
  margin-left: 5px;
}

.editableContent .hidden {
  display: none;
}

.editableContent .placeholder {
  color: #ddd;
}

.DashboardHeader {
  margin-bottom: 10px;
}

.DashboardHeader .DashboardTitle,
.DashboardHeader .DashboardDescription {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.DashboardTitle h2 {
  margin: 0;
}

.DashboardTitle button.editButton {
  margin: -0px 0 0 5px;
}

.DashboardHeader .hidden {
  display: none;
}

.ChartGroup {
  background: var(--sectionBackground);
  margin: 10px 0;
  border: 0.5px solid var(--secondaryBackground_darker);
  border-radius: 5px;
  transition: background 0.2s, color 0.2s;
}

.ChartGroup > header {
  /* background-color: var(--secondaryBackground_darker); */
  padding: 10px 0;
  margin: 10px;
  border-bottom: 1px solid var(--dividerColor);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.ChartGroup.isClosed > header {
  border-radius: 5px;
  border-bottom: none;
}

.ChartGroup header h3 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0;
}

.ChartGroup header h3 span.bp3-icon {
  margin: 0 10px 0 5px;
}

.ChartGroup .chartGroupContainer {
  padding: 10px;
}

.ChartGroup.isClosed .chartGroupContainer {
  padding: 0;
}

.ChartGroup .ChartParameters {
  display: flex;
  justify-content: space-between;
  margin: 20px 0 10px;
}

.ChartGroup .ChartParameters > .parametersGroup {
  flex: 1 1;
}

.ChartGroup .ChartParameters > .parametersGroup > span {
  font-style: italic;
  display: flex;
  justify-content: center;
}

.ChartGroup .ChartParameters .divider {
  visibility: hidden;
  width: 1px;
  border: 0.5px solid var(--dividerColor);
  margin: 27px 10px 40px;
}

.ChartGroup .chartGroupContainer .updateChart {
  margin-top: 10px;
}

.ChartGroup .chartGroupContainer .validationMessage {
  font-size: 0.7rem;
  color: #df4846;
}

.ChartGroup .chartGroupContainer .chartGroupFooter {
  display: flex;
  justify-content: space-between;
}
.Chart {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Chart .sortButton {
  height: 20px;
}
.ChartParametersHeader {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  margin-bottom: 10px;
}

.chartNamePopover {
  width: 300px;
  margin: 0;
}

.chartNamePopover .buttons {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
}

.chartNamePopover .buttons button {
  margin-left: 10px;
}

.Filter {
  background: var(--elevatedBackground);
  padding: 5px 5px 5px 10px;
  margin: 10px 0;
  border-radius: 5px;
  transition: background 0.2s, color 0.2s;
}

.Filter .filterSelection {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Filter .filterSelection span.bp3-icon{
  color: var(--mutedTextColor);
  margin: 0 10px 0 3px;
}

.Filter .filterSelection button {
  margin-left: 5px;
}

.Filter .filterParameters {
  margin: 5px 0 0 25px;
}

.Filter .filterValues {
  margin: -1px 35px 0 33px;
}

.iconsGroup {
  display: flex;
  flex-direction: row;
  padding: 5px;
}

.iconsSubGroup {
  display: flex;
  flex-direction: column;
}
.attributeParametersPopup {
  /* width: 300px; */
  padding: 10px;
  font-size: 0.8rem;
}

.attributeParametersPopup input {
  width: 50px !important;
}

.Attribute {
  background: var(--elevatedBackground);
  padding: 5px 5px 5px 10px;
  margin: 10px 0;
  border-radius: 5px;
  transition: background 0.2s, color 0.2s;
}

.Attribute .attributeSelection {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Attribute .attributeSelection span.bp3-icon {
  color: var(--mutedTextColor);
  margin: 0 10px 0 3px;
}

.Attribute .attributeSelection button {
  margin-left: 5px;
}

.Attribute .attributeParameters {
  margin: 5px 0 0 33px;
}
.parametersGroupHeader {
  color: var(--mutedTextColor);
  transition: color 0.2s;
}
.parametersGroup .parametersGroupHeader {
  margin-bottom: 10px;
}

.parametersGroup .addButtonContainer {
  display: flex;
  justify-content: center;
}
.titan .notFound {
  padding: 200px 0 0 140px;
  display: flex;
  justify-content: center;
}

.titan .notFound p {
  font-size: 1.5rem;
  color: #777;
  padding-left: 20px;
}

/* .MainContainer {
  background: var(--appBackground);
  color: var(--primaryTextColor);
  transition: background 0.2s, color 0.2s;
} */

.PageContainer {
  margin: 10px;
  padding: 10px;
}

h2 {
  margin-top: 0;
  font-weight: 600;
}
