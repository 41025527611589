body.bp3-light {
  --appBackground: #f5f8fa;
  --elevatedBackground: #EBF1F5;
  --pronouncedBackground: #ced9e0;
  --cardBackground: #f5f8fa;
  --sideBarBackground: #202B33;
  --sectionBackground: #FFFFFF;
  --primaryTextColor: #3b4e5d;
  --mutedTextColor: #4A5D6C;
  --disabledTextColor: #5C7080;
  --dividerColor: #c0ccd6;
  --shadow: #30404d;
  --iconFill: #5c7080;

  --primaryBackground: #f5f7f7;
  --secondaryBackground_lightest: #ebf1f5;
  --secondaryBackground_lighter: #e1e8ed;
  --secondaryBackground: #ced9e0;
  --secondaryBackground_darker: #bfccd6;
  --linkTextColor: #0f6ba3;
  --darkHeadingBackground: #394b59;
  --darkHeadingBackgroundPlus: #30404d;
  --darkHeadingTextColor: #f5f7f7;
  --darkBorder: #27343F;
  transition: background 0.2s, color 0.2s;
}
