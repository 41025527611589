body.bp3-dark {
  --appBackground: #30404d;
  --elevatedBackground: #394B59;
  --pronouncedBackground: #4A5D6C;
  --cardBackground: #3e4c5c;
  --sectionBackground: #293742;
  --sideBarBackground: #202B33;
  --primaryTextColor: #F5F8FA;
  --mutedTextColor: #A7B6C2;
  --disabledTextColor: #778793;
  --dividerColor: #778793;
  --linkTextColor: #0f6ba3;
  --shadow: #30404d;
  --iconFill: #a7b6c2;
  transition: background 0.2s, color 0.2s;
}
