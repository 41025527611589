.titan .notFound {
  padding: 200px 0 0 140px;
  display: flex;
  justify-content: center;
}

.titan .notFound p {
  font-size: 1.5rem;
  color: #777;
  padding-left: 20px;
}
