/* .MainContainer {
  background: var(--appBackground);
  color: var(--primaryTextColor);
  transition: background 0.2s, color 0.2s;
} */

.PageContainer {
  margin: 10px;
  padding: 10px;
}

h2 {
  margin-top: 0;
  font-weight: 600;
}