.SideBar {
  background: var(--sideBarBackground);
  color: #e6e9ed;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 67px;
  height: 100%;
  /* overflow: auto; */
  overflow-x: hidden;
  overflow-y: auto;
  white-space: nowrap;
  padding-top: 50px;
  transition: width 0.15s;
  z-index: 10;
}

.SideBar.open {
  width: 250px;
}

button.openCloseButton {
  margin: 18px;
  outline: none;
}

button.openCloseButton:hover {
  background: var(--sideBarBackground) !important;
}

button.openCloseButton svg {
  color: #e6e9ed;
}

.MenuGroup {
  position: static;
}

.MenuGroup:first-child {
  margin-top: 20px;
}

.MenuGroupHeader {
  display: flex;
  align-items: center;
  font-size: 1rem;
  color: #e6e9ed;
  margin-bottom: 0;
}

.MenuGroupHeader > span.MenuGroupHeaderIcon {
  padding: 15px 21px;
}

.MenuGroupHeader > span.MenuGroupTitle {
  margin-top: 10px;
}

.MenuGroup.isClosed .MenuGroupHeader > span.hovered {
  background: #334b5a;
}

.MenuGroupHeader:hover {
  background: #334b5a;
  color: #e6e9ed;
  cursor: pointer;
}

.MenuGroupHeader.active {
  background: #202b33;
}

.MenuGroupTitle {
  position: relative;
  top: -4px;
}

.MenuGroupItem {
  display: block;
  font-size: 0.8rem;
  color: #e6e9ed !important;
  margin: 5px 0 5px 20px;
  padding: 5px 7px;
}

.MenuGroupItem.active {
  font-weight: 700;
  padding-left: 7px;
  border-left: solid 3px #e6e9ed;
}

.MenuGroupItem:hover {
  background: #334b5a;
  color: #e6e9ed;
  cursor: pointer;
}

.MenuGroup.isClosed:hover .MenuGroupHover {
  display: block;
  background: #334b5a;
}

.MenuGroupHover {
  background: #334b5a;
  position: fixed;
  top: 0;
  left: 67px;
  width: 200px;
  padding: 0;
}

.MenuGroupHoverTitle {
  font-size: 1rem;
  font-weight: 600;
  padding: 20px;
  padding-bottom: 2px;
}

.MenuGroupHoverItem {
  display: block;
  color: #e6e9ed !important;
  margin: 0;
  padding: 10px 20px;
  font-size: 0.8rem;
  cursor: pointer;
}

.MenuGroupHoverItem:last-child {
  margin-bottom: 10px;
}

.MenuGroupHoverItem:hover {
  background: #5c7080;
  color: #e6e9ed;
}

.MenuGroupHoverItem.active {
  font-weight: 700;
  padding-left: 17px;
  border-left: solid 3px #e6e9ed;
}


footer.buildNumber {
  position: absolute;
  bottom: 0;
  font-size: 0.7rem;
  font-style: italic;
  padding: 10px;
}