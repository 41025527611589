
.ExplorerScreenshotsList .screenshotsControls {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ExplorerScreenshotsList .controlButtons {
  margin-top: 15px;
  display: flex;
  justify-content: flex-start;
}

.ExplorerScreenshotsList .controlButtons button {
  margin-right: 15px;
}

.ExplorerScreenshotsList .screenshotsControls .screenshotsCounts {
  color: var(--mutedTextColor);
  font-size: 0.85rem;
  margin: 20px 0 0;
}


.ExplorerScreenshotsListTable {
  background: var(--sectionBackground);
  border: solid 1px var(--pronouncedBackground);
  border-radius: 5px;
  margin-top: 10px;
  transition: background 0.2s, color 0.2s;
}

.ExplorerScreenshotsListTable thead tr th {
  padding: 10px 0;
  border-bottom: solid 1px var(--dividerColor);
}

.ExplorerScreenshotsListTable tbody tr:hover {
  background: var(--elevatedBackground);
}

.ExplorerScreenshotsListTable tbody {
  padding: 0 10px;
}

.ExplorerScreenshotsListTable tbody tr td {
  padding: 0;
  height: 35px;
}

.ExplorerScreenshotsListTable tbody tr td:last-child {
  padding-right: 10px;
}

.ExplorerScreenshotsListTable tbody tr td p {
  padding: 5px 0;
}

.ExplorerScreenshotsListTable tbody tr td:hover {
  cursor: pointer;
}

.ExplorerScreenshotsListTable tr:not(:first-child) td {
  border-top: solid 1px var(--elevatedBackground);
}

.ExplorerScreenshotsListTable {
  width: 100%;
  table-layout: fixed;
  overflow-wrap: break-word;
}

.ExplorerScreenshotsListTable p {
  margin-bottom: 0;
}
.ExplorerScreenshotsListTable label {
  margin-bottom: 0;
}

.ExplorerScreenshotsListTable thead {
  margin: 5px;
}

.ExplorerScreenshotsListTable thead th {
  padding-bottom: 5px;
  margin-bottom: 5px;
  cursor: pointer;
}

.ExplorerScreenshotsListTable td {
  text-align: center;
  padding: 2px 0;
}

/****************************
*****   Loading state   *****
****************************/

.ExplorerScreenshotsListTable tbody.loading tr td {
  height: 200px;
  width: 100%;
}