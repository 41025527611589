.ChartGroup {
  background: var(--sectionBackground);
  margin: 10px 0;
  border: 0.5px solid var(--secondaryBackground_darker);
  border-radius: 5px;
  transition: background 0.2s, color 0.2s;
}

.ChartGroup > header {
  /* background-color: var(--secondaryBackground_darker); */
  padding: 10px 0;
  margin: 10px;
  border-bottom: 1px solid var(--dividerColor);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.ChartGroup.isClosed > header {
  border-radius: 5px;
  border-bottom: none;
}

.ChartGroup header h3 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0;
}

.ChartGroup header h3 span.bp3-icon {
  margin: 0 10px 0 5px;
}

.ChartGroup .chartGroupContainer {
  padding: 10px;
}

.ChartGroup.isClosed .chartGroupContainer {
  padding: 0;
}

.ChartGroup .ChartParameters {
  display: flex;
  justify-content: space-between;
  margin: 20px 0 10px;
}

.ChartGroup .ChartParameters > .parametersGroup {
  flex: 1 1 0px;
}

.ChartGroup .ChartParameters > .parametersGroup > span {
  font-style: italic;
  display: flex;
  justify-content: center;
}

.ChartGroup .ChartParameters .divider {
  visibility: hidden;
  width: 1px;
  border: 0.5px solid var(--dividerColor);
  margin: 27px 10px 40px;
}

.ChartGroup .chartGroupContainer .updateChart {
  margin-top: 10px;
}

.ChartGroup .chartGroupContainer .validationMessage {
  font-size: 0.7rem;
  color: #df4846;
}

.ChartGroup .chartGroupContainer .chartGroupFooter {
  display: flex;
  justify-content: space-between;
}