.dashboardItemContainer {
  margin: 10px;
  max-width: 400px;
  flex-basis: 300px;
  flex-grow: 1;
  position: relative;
  background: var(--cardBackground) !important;
  /* border: 0.5px solid var(--dividerColor); */
  border-radius: 5px;
  padding: 15px;
  transition: background 0.2s, color 0.2s;
}

.dashboardItemContainer:hover {
  box-shadow: 0 0 10px var(--shadow);
}

.dashboardItem {
  height: 90px;
  overflow-y: hidden;
}

.dashboardItemName {
  max-width: 90%;
}

.dashboardItem h3 {
  margin: 0;
  overflow: hidden;
}

.dashboardItem p {
  font-size: 0.9rem;
  font-style: italic;
}

.dashboardItemHeader {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 5px;
}

.dashboardItemButtons {
  display: flex;
}

.dashboardItemButtons button {
  margin-left: 10px;
}
